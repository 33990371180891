// NOTE: hooks
import React, { lazy, ReactNode, Suspense, useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useRedirectToAuth from '../hooks/useRedirectToAuth'
// NOTE: components
import Loading from '../components/ui/Loading'
import Layout from '../components/ui/Layout'
// NOTE: images
import clinicianIcon from '../assets/img/logo/clinicIcon.png'
import clinicIcon from '../assets/img/navIcons/peopleGradient.png'
import { useAuth } from '../hooks/useAuth'
// NOTE: main pages
const Patient = lazy(() => import('./patient/Patient'))

const navLinks = [
  {
    to: '/patients/',
    text: 'Patients',
    icon: clinicIcon
  }
]

interface LoadPageProps {
  page: ReactNode
}

// NOTE: component to show loading animation
const LoadPage = ({ page }: LoadPageProps) => (
  <Suspense fallback={<Loading size={undefined} />}>{page}</Suspense>
)

const ProtectedClinician = () => {
  const redirectToAuth = useRedirectToAuth()
  const { logout } = useAuth()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // TODO: use the user object from the context instead of getting it from local storage

    // NOTE: get the saved user object from local storage from previous logins
    const userLocalStorage = localStorage.getItem('user')
    const savedUser = userLocalStorage && JSON.parse(userLocalStorage)

    if (!savedUser) return redirectToAuth()

    // NOTE: when user use authenticated with the old token structure - this won't be needed with auth.js
    if (savedUser.displayName || savedUser.position) return logout()

    // NOTE: check user role and permission
    if (!savedUser.clinician && !savedUser.admin) return logout()

    setLoading(false)
  }, [])

  if (loading) return <Loading size={undefined} />

  return <Outlet />
}

const clinicianRoutes = [
  {
    element: <ProtectedClinician />,
    children: [
      {
        element: <Layout links={navLinks} home='/' icon={clinicianIcon} />,
        children: [
          {
            index: true,
            element: <Navigate to='patients/' />
          },
          {
            path: 'patients',
            element: <LoadPage page={<Patient />} />
          }
        ]
      }
    ]
  }
]

export default clinicianRoutes
